@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind typography; */

:root{
    --toastify-toast-min-height: 50px;
}
.Toastify__toast{
    font-size: 14px;
}
.Toastify__progress-bar {
    height: 3px;
}
.Toastify__toast-icon {
    width: 18px;
}